export default {
  data: function () {
    var productId = this.$route.params.id;
    var currentProduct;
    this.$root.products.forEach(function (product) {
      if (product.id === productId) {
        currentProduct = product;
      }
    });
    return {
      product: currentProduct
    };
  },
  id: 'afc45ee972',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page" data-name="product">\r\n    <div class="navbar">\r\n      <div class="navbar-bg"></div>\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="#" class="link back">\r\n            <i class="icon icon-back"></i>\r\n            <span class="if-not-md">Back</span>\r\n          </a>\r\n        </div>\r\n        <div class="title">';
      r += c(ctx_1.product.title, ctx_1);
      r += '</div>\r\n      </div>\r\n    </div>\r\n    <div class="page-content">\r\n      <div class="block-title">About ';
      r += c(ctx_1.product.title, ctx_1);
      r += '</div>\r\n      <div class="block block-strong">\r\n        ';
      r += c(ctx_1.product.description, ctx_1);
      r += '\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },

  styleScoped: false
};