
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '9303f44d6d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page">\r\n    <div class="navbar">\r\n      <div class="navbar-bg"></div>\r\n      <div class="navbar-inner sliding">\r\n        <div class="left">\r\n          <a href="#" class="back link">\r\n            <i class="icon icon-back"></i>\r\n            <span class="if-not-md">Back</span>\r\n          </a>\r\n        </div>\r\n        <div class="title">';
      r += c(ctx_1.user.firstName, ctx_1);
      r += ' ';
      r += c(ctx_1.user.lastName, ctx_1);
      r += '</div>\r\n      </div>\r\n    </div>\r\n    <div class="page-content">\r\n      <div class="block block-strong">\r\n        ';
      r += c(ctx_1.user.about, ctx_1);
      r += '\r\n      </div>\r\n      <div class="list links-list">\r\n        <ul>\r\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.user.links, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <li><a class="external" target="_blank" href="';
          r += c(ctx_2.url, ctx_2);
          r += '">';
          r += c(ctx_2.title, ctx_2);
          r += '</a></li>\r\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n        </ul>\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    