import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';



// Import Routes
import routes from './routes.js';



var app = new Framework7({
  root: '#app', // App root element

  name: '1st Foot Forward', // App name
  theme: 'auto', // Automatic theme detection
  navbar: { mdCenterTitle: true },

  on: {
    // each object key means same name event handler
    pageInit: function (page) {
      // do something on page init

        if(page.name === "catalog"){

                var today = moment();
                var Weeks = [];
                var WeeksCalendar = [];
                var MomentDay = [];
                var day = "";
                var dayCalendar = "";
                for (var i = 1; i <= 8; i++) {
                  today.add(1, 'days');
                  dayCalendar = today.format("dddd, MMMM D");
                  day = today.format("dddd");
                  //if(day != "Sunday"){
                    Weeks[Weeks.length] = day;
                    WeeksCalendar[WeeksCalendar.length] = dayCalendar;
                  //}
                }


                  $$( ".day-slide" ).each(function( i ) {
                     $$(this).addClass( Weeks[i]);
                     $$(this).children(".navbar").children(".navbar-inner").children( ".title").html(WeeksCalendar[i]);
                  });
                  $$( ".booking-row" ).each(function( i ) {  
                      $$(this).addClass( Weeks[i]);
                      $$(this).children(".navbar").children(".navbar-inner").children( ".title").html(WeeksCalendar[i]);
                  });

                  

          fillTimeTable();
        }
        if(page.name === "bookings"){
          fillBookingList();
        }
        if(page.name === "about"){
          fillTimeline();
        }
        if(page.name === "unlimited"){
          unlimitedList();
        }
        if(page.name === "unlimited"){
            var calendarDefault = app.calendar.create({
              inputEl: '#unlimited-picker-from',
            });
            var calendarDefault = app.calendar.create({
              inputEl: '#unlimited-picker-till',
            });
        }
        if(page.name === "ptsessions"){
          ptsessionsList();
          var pickerDescribe = app.picker.create({
            inputEl: '#ptsession-picker-when',
              rotateEffect: true,
              cols: [
                {
                  textAlign: 'left',
                  values: ('Mon Tue Wed Thu Fri Sat Sun').split(' ')
                },
                {
                  values: ('6. 7. 8. 9. 10. 11. 12. 1. 2. 3. 4. 5.').split(' ')
                },
                {
                  values: ('00am 30am 00pm 30pm').split(' ')
                },
              ]
          });
        }
        if(page.name === "account"){
            
            var stripe = Stripe('pk_live_coJBhsdjz92nz62wn6hivrOc');
            var elements = stripe.elements();

            // Custom styling can be passed to options when creating an Element.
            var style = {
              base: {
                // Add your base input styles here. For example:
                fontSize: '18px',
                lineHeight: '24px'
              }
            };

            // Create an instance of the card Element
            var card = elements.create('card', {style: style});

            // Add an instance of the card Element into the `card-element` <div>
            card.mount('#card-element');

            
            var form = document.getElementById('payment-form');
            
                    form.addEventListener('submit', function(event) {
                      event.preventDefault();
                      console.log("step1");
                      $(".credit-1").addClass("hide");
                      $(".credit-2").removeClass("hide");
                      stripe.createToken(card).then(function(result) {
                        if (result.error) {
                          // Inform the user if there was an error
                          var errorElement = document.getElementById('card-errors');
                          errorElement.textContent = result.error.message;
                        } else {
                          // Send the token to your server
                          stripesend(result.token.id);
                        }
                      });
                    });
           
        }

        if(page.name === "payments"){
          paymentList();
        }
        
    },
    pagereinit: function (page) {
        if(page.name === "bookings"){
          fillBookingList();
        }
        if(page.name === "home"){
          fillBookingList();
          loadPage();
        }
        if(page.name === "account"){
          updateAccounts();
        }
        if(page.name === "payments"){
          paymentList();
        }
    },
    pageafterin: function (page) {

        
    },
    popupOpen: function (popup) {
      fillClassList();
      // $('.selector').click(function () {
      //       console.log("app");
      //       $(".peopleClass").html(" ")
      //       $(".button").removeClass("button-fill");
      //       currentClass = $(this).attr('num');
      //       $(".login-screen-title").html( ClassSchedule[ currentClass ].class );
      //       $(".subtitle").html( ClassSchedule[currentClass].displayday + ", " + ClassSchedule[currentClass].displaytime );
           
      //       for (var i = 0; i < 4; i++) {
      //           possibleClass[i] = MomentDay[TextDay.indexOf( ClassSchedule[ currentClass ].displayday )].clone().add(i, 'weeks');
      //           $("#joinClass"+ (i + 1)).removeClass();
               
      //           $("#joinClass"+ (i + 1)).html( possibleClass[i].format("dddd, MMMM D") );
      //           $("#joinClass"+ (i + 1)).addClass( ClassSchedule[ currentClass ].code + possibleClass[i].format("DDD"));

      //           $("#joinClass"+ (i + 1)).addClass("col button button-large button-raised");
            
      //           if(ClassSchedule[ currentClass ].people.length > 0){      
      //             if(ClassSchedule[ currentClass ].people["D" + possibleClass[i].dayOfYear()] != undefined){
      //               for(x in ClassSchedule[ currentClass ].people["D" + possibleClass[i].dayOfYear()]){
      //                   console.log(ClassSchedule[ currentClass ].people["D" + possibleClass[i].dayOfYear()][x]);
      //                   $("#peopleClass"+ (parseInt(i) + 1)).append( "<span class='removeable-people'> - " + ClassSchedule[ currentClass ].people["D" + possibleClass[i].dayOfYear()][x] + "  </span>" );
      //               }
      //             }
      //           }
      //       }
            
      //       marker();

      //       // return firebase.database().ref('group/' + ClassSchedule[ currentClass ].code  ).once('value').then(function(snapshot) {
                 
      //       //       var data = snapshot.val();
      //       //       //"D" + possibleClass[z].dayOfYear()
      //       //       for(x in data)  {
      //       //         for(z in possibleClass)
      //       //         {
      //       //             if("D" + possibleClass[z].dayOfYear() == x){
      //       //                 for(y in data[x]){
      //       //                   $("#peopleClass"+ (parseInt(z) + 1)).append( " - " + data[x][y].person + "  " );
      //       //                 }
      //       //             }
      //       //         }
      //       //       }
      //       // }); 
      //   });
    },
  },


  // App root methods
  methods: {
    helloWorld: function () {
      app.dialog.alert('Hello World!');
    }
  },
  // App routes
  routes: routes,
  // Register service worker
  serviceWorker: {
    path: '/service-worker.js',
  },
});





var currentClass = 0;

// Login Screen Demo
$$('#my-login-screen .login-button').on('click', function () {
  var username = $$('#my-login-screen [name="username"]').val();
  var password = $$('#my-login-screen [name="password"]').val();
  

  // Close login screen
  app.loginScreen.close('#my-login-screen');

  // Alert username and password
  //app.dialog.alert('Username: ' + username + '<br>Password: ' + password);
});

$$('.login-screen').on('loginscreen:open', function (e) {
    fillModal();
});





var clonedDiv = "";

function fillTimeTable() {

    for (var i = 0; i < ClassSchedule.length; i++) {
      if(ClassSchedule[i].active){

        var div = $('#template-class').clone();
        div.attr("id", ClassSchedule[i].code);
        div.addClass("tg-" + ClassSchedule[i].group);
        div.addClass("online-" + ClassSchedule[i].online);
        div.children(".selector").attr("num", i);

        div.children(".item-content").children(".item-media").children("img").attr("src","static/img/" + ClassSchedule[i].class + ".jpg");
        if(ClassSchedule[i].online){
          div.children(".item-content").children(".item-inner").children(".item-title-row").children(".item-title").html("Online - <b>" + ClassSchedule[i].class + "</b>");
        } else {
          div.children(".item-content").children(".item-inner").children(".item-title-row").children(".item-title").html("<b>" + ClassSchedule[i].class + "</b>");
        }
        div.children(".item-content").children(".item-inner").children(".item-subtitle").html(ClassSchedule[i].displayday + ", " + ClassSchedule[i].displaytime);
        div.removeClass("hide");

        $('.' + ClassSchedule[i].displayday ).children(".list").children("ul").append( div );
      }
    }

     //$('.Sunday'  ).addClass("hide");

}

function fillClassList() {
  $(".removeable").remove();
    
    for (var i = 0; i < ClassSchedule.length; i++) {
      if(ClassSchedule[i].active){
        var div = $('#template-class-small').clone();
        div.attr("id", ClassSchedule[i].code);
        div.children(".selector").attr("num", i);
        div.attr("onclick", "selector("+i+")");
        div.addClass("removeable");
        div.children(".item-content").children(".item-media").children("img").attr("src","static/img/" + ClassSchedule[i].class + ".jpg");
        div.children(".item-content").children(".item-inner").children(".item-title-row").children(".item-title").html("<b> V" + ClassSchedule[i].class + "</b>");
        div.children(".item-content").children(".item-inner").children(".item-subtitle").html(ClassSchedule[i].displayday + ", " + ClassSchedule[i].displaytime);
        div.removeClass("hide");
        $('.' + ClassSchedule[i].class.toLowerCase().replace(/[^A-Z0-9]/ig, "") ).append( div );       
      }
    }
}

function fillModal() {
    
    //$(".login-screen-title").html( ClassSchedule[ currentClass ].class );
}

function close8Week() {
    app.card.close("#eightweek", true);
}

var firebaseConfig = {
    apiKey: "AIzaSyA0tRB0KSnpAqc-X2pimLFPGIiF8_DLi04",
    authDomain: "firstfootgeneral.firebaseapp.com",
    databaseURL: "https://firstfootgeneral.firebaseio.com",
    projectId: "firstfootgeneral",
    storageBucket: "firstfootgeneral.appspot.com",
    messagingSenderId: "14721757091",
    appId: "booking",
    measurementId: "G-measurement-id",
  };
  firebase.initializeApp(firebaseConfig);

var database = firebase.database();

function stripesend( stripetoken ) {
      
    var amount = $('input[name="amount-radio"]:checked').val();  
    var oReq = new XMLHttpRequest();      
           $.post( "https://mighty-shelf-11601.herokuapp.com/stripe/charge.json", {"stripetoken": stripetoken , "name": UserName, "amount": amount, "delivery": "", "address": "sd", "token":"token", "email": EMAIL} )
                    .done(function( data ) { console.log( data ); addCreditCard(amount); })
                    .fail(function(data) {
                        $(".credit-2").addClass("hide");
                        $(".credit-1").removeClass("hide");
                        console.log(data)
                        $("#errormessage").html( 'Please check your order and try again.\nError: <span>' + data + "</span>");                      
              });

}

function firstTime(){
    app.router.navigate('/form/');
}
